import { inject }          from 'aurelia-framework';
import { BaseFormSchema }  from 'resources/classes/base-form-schema';
import { LocalStorage }    from 'resources/services/local-storage';
import { EventAggregator } from 'aurelia-event-aggregator';
import { I18N }            from 'aurelia-i18n';
import { DialogService }   from 'aurelia-dialog';

@inject(LocalStorage, EventAggregator, I18N, DialogService)
export class BaseFilterFormSchema extends BaseFormSchema {

    modelDefaults = {};

    /**
     * Constructor
     *
     * @param storage
     * @param eventAggregator
     * @param i18n
     * @param dialogService
     */
    constructor(storage, eventAggregator, i18n, dialogService) {
        super(eventAggregator, i18n, dialogService);

        this.storage = storage;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns
     */
    filterModel(viewModel, defaultFilterModel) {
        let savedListingInfo = this.storage.get(viewModel.listingId);

        if (savedListingInfo) {
            defaultFilterModel = JSON.parse(savedListingInfo).criteria || defaultFilterModel;
        }

        return defaultFilterModel;
    }

}
