let AureliaDialogConfig = {
    configure: (config) => {
        config.useDefaults();
        config.settings.lock                 = true;
        config.settings.centerHorizontalOnly = true;
        config.settings.overlayDismiss       = false;
        config.settings.keyboard             = false;

        return config;
    },
};

export { AureliaDialogConfig };
