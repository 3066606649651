import environment from 'environment';

let GlobalConfig = {
    backendBaseUrl:    environment.backendBaseUrl,
    backendBaseApiUrl: environment.backendBaseUrl + '/api/',
    defaultListView:   'resources/views/default/list.html',
    defaultFormView:   'resources/views/default/form.html',
    generateApiUrl:    (relativeUrl) => environment.backendBaseUrl + '/api/' + relativeUrl,
};

export { GlobalConfig };
