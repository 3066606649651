import { inject, PLATFORM }           from 'aurelia-framework';
import { activationStrategy, Router } from 'aurelia-router';
import { AuthenticateStep }           from 'aurelia-authentication';
import { MenuAccessStep }             from 'resources/services/menu-access-step';
import environment                    from 'environment';

@inject(Router)
export class RouterConfig {

    /**
     * Constructor
     *
     * @param router
     */
    constructor(router) {
        this.router = router;
    }

    /**
     * Configure router
     */
    configure() {
        let appRouterConfig = (config) => {
            config.title = environment.productName;

            config.addPipelineStep('authorize', AuthenticateStep);
            config.addPreActivateStep(MenuAccessStep);

            config.map([
                // ==================== auto login ====================
                // (this is needed to generate auto login link, ensure
                // it always remains equal to unauthenticated link)
                {
                    route:    ['auto-login/:token/:signature'],
                    name:     'auto-login',
                    moduleId: PLATFORM.moduleName('auto-login'),
                    nav:      false,
                    title:    'Auto login',
                },
                // ==================== dashboard ====================
                {
                    route:    ['', 'dashboard'],
                    name:     'dashboard',
                    moduleId: PLATFORM.moduleName('modules/core/dashboard/index'),
                    nav:      true,
                    title:    'Dashboard',
                },
                // ==================== user-profile ====================
                {
                    route:    'user-profile',
                    name:     'user-profile.index',
                    moduleId: PLATFORM.moduleName('modules/core/user-profile/index'),
                    nav:      true,
                    title:    'Perfil de utilizador',
                    settings: {
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.user-profile'},
                        ],
                    },
                },
                // ==================== mail-settings ====================
                {
                    route:    'wemake/mail-settings/edit',
                    name:     'wemake.mail-settings.edit',
                    moduleId: PLATFORM.moduleName('modules/wemake/mail-settings/edit/index'),
                    nav:      true,
                    title:    'Configurações de email',
                    settings: {
                        menu:        'wemake',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.wemake'},
                            {title: 'page.breadcrumbs.mail-settings'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                // ==================== translations ====================
                {
                    route:    'wemake/translations',
                    name:     'wemake.translations.index',
                    moduleId: PLATFORM.moduleName('modules/wemake/translations/index'),
                    nav:      true,
                    title:    'Traduções',
                    settings: {
                        menu:        'wemake',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.wemake'},
                            {title: 'page.breadcrumbs.translations'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                {
                    route:    'wemake/translations/create',
                    name:     'wemake.translations.create',
                    moduleId: PLATFORM.moduleName('modules/wemake/translations/create/index'),
                    nav:      false,
                    title:    'Criar tradução',
                    settings: {
                        menu:        'wemake',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.wemake'},
                            {title: 'page.breadcrumbs.translations'},
                            {title: 'page.breadcrumbs.new-record'},
                        ],
                    },
                },
                {
                    route:    'wemake/translations/:id/edit',
                    name:     'wemake.translations.edit',
                    moduleId: PLATFORM.moduleName('modules/wemake/translations/edit/index'),
                    nav:      false,
                    title:    'Editar tradução',
                    settings: {
                        menu:        'wemake',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.wemake'},
                            {title: 'page.breadcrumbs.translations'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                // ==================== service-types ====================
                {
                    route:    'wemake/service-types',
                    name:     'wemake.service-types.index',
                    moduleId: PLATFORM.moduleName('modules/wemake/service-types/index'),
                    nav:      true,
                    title:    'List service types',
                    settings: {
                        menu:        'wemake',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.wemake'},
                            {title: 'page.breadcrumbs.service-types'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                {
                    route:    'wemake/service-types/create',
                    name:     'wemake.service-types.create',
                    moduleId: PLATFORM.moduleName('modules/wemake/service-types/create/index'),
                    nav:      false,
                    title:    'Create service type',
                    settings: {
                        menu:        'wemake',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.wemake'},
                            {title: 'page.breadcrumbs.service-types'},
                            {title: 'page.breadcrumbs.new-record'},
                        ],
                    },
                },
                {
                    route:    'wemake/service-types/:id/edit',
                    name:     'wemake.service-types.edit',
                    moduleId: PLATFORM.moduleName('modules/wemake/service-types/edit/index'),
                    nav:      false,
                    title:    'Edit service type',
                    settings: {
                        menu:        'wemake',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.wemake'},
                            {title: 'page.breadcrumbs.service-types'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                {
                    route:    'wemake/service-types/:id/view',
                    name:     'wemake.service-types.view',
                    moduleId: PLATFORM.moduleName('modules/wemake/service-types/view/index'),
                    nav:      false,
                    title:    'View service type',
                    settings: {
                        menu:        'wemake',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.wemake'},
                            {title: 'page.breadcrumbs.service-types'},
                            {title: 'page.breadcrumbs.view-record'},
                        ],
                    },
                },
                // ==================== consultants ====================
                {
                    route:    'wemake/consultants',
                    name:     'wemake.consultants.index',
                    moduleId: PLATFORM.moduleName('modules/wemake/consultants/index'),
                    nav:      true,
                    title:    'List consultants',
                    settings: {
                        menu:        'wemake',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.wemake'},
                            {title: 'page.breadcrumbs.consultants'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                {
                    route:    'wemake/consultants/create',
                    name:     'wemake.consultants.create',
                    moduleId: PLATFORM.moduleName('modules/wemake/consultants/create/index'),
                    nav:      false,
                    title:    'Create consultant',
                    settings: {
                        menu:        'wemake',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.wemake'},
                            {title: 'page.breadcrumbs.consultants'},
                            {title: 'page.breadcrumbs.new-record'},
                        ],
                    },
                },
                {
                    route:    'wemake/consultants/:id/edit',
                    name:     'wemake.consultants.edit',
                    moduleId: PLATFORM.moduleName('modules/wemake/consultants/edit/index'),
                    nav:      false,
                    title:    'Edit consultant',
                    settings: {
                        menu:        'wemake',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.wemake'},
                            {title: 'page.breadcrumbs.consultants'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                {
                    route:    'wemake/consultants/:id/view',
                    name:     'wemake.consultants.view',
                    moduleId: PLATFORM.moduleName('modules/wemake/consultants/view/index'),
                    nav:      false,
                    title:    'View consultant',
                    settings: {
                        menu:        'wemake',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.wemake'},
                            {title: 'page.breadcrumbs.consultants'},
                            {title: 'page.breadcrumbs.view-record'},
                        ],
                    },
                },
                // ==================== clients ====================
                {
                    route:    'wemake/clients',
                    name:     'wemake.clients.index',
                    moduleId: PLATFORM.moduleName('modules/wemake/clients/index'),
                    nav:      true,
                    title:    'List clients',
                    settings: {
                        menu:        'wemake',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.wemake'},
                            {title: 'page.breadcrumbs.clients'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                {
                    route:    'wemake/clients/create',
                    name:     'wemake.clients.create',
                    moduleId: PLATFORM.moduleName('modules/wemake/clients/create/index'),
                    nav:      false,
                    title:    'Create client',
                    settings: {
                        menu:        'wemake',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.wemake'},
                            {title: 'page.breadcrumbs.clients'},
                            {title: 'page.breadcrumbs.new-record'},
                        ],
                    },
                },
                {
                    route:    'wemake/clients/:id/edit',
                    name:     'wemake.clients.edit',
                    moduleId: PLATFORM.moduleName('modules/wemake/clients/edit/index'),
                    nav:      false,
                    title:    'Edit client',
                    settings: {
                        menu:        'wemake',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.wemake'},
                            {title: 'page.breadcrumbs.clients'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                {
                    route:    'wemake/clients/:id/view',
                    name:     'wemake.clients.view',
                    moduleId: PLATFORM.moduleName('modules/wemake/clients/view/index'),
                    nav:      false,
                    title:    'View client',
                    settings: {
                        menu:        'wemake',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.wemake'},
                            {title: 'page.breadcrumbs.clients'},
                            {title: 'page.breadcrumbs.view-record'},
                        ],
                    },
                },
                // ==================== messages ====================
                {
                    route:    'personal-area/messages',
                    name:     'personal-area.messages.index',
                    moduleId: PLATFORM.moduleName('modules/personal-area/messages/index'),
                    nav:      true,
                    title:    'Mensagens',
                    settings: {
                        menu:        'personal-area',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.personal-area'},
                            {title: 'page.breadcrumbs.messages'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                {
                    route:    'personal-area/messages/create',
                    name:     'personal-area.messages.create',
                    moduleId: PLATFORM.moduleName('modules/personal-area/messages/create/index'),
                    nav:      false,
                    title:    'Criar mensagem',
                    settings: {
                        menu:        'personal-area',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.personal-area'},
                            {title: 'page.breadcrumbs.messages'},
                            {title: 'page.breadcrumbs.new-record'},
                        ],
                    },
                },
                {
                    route:    'personal-area/messages/:id/details',
                    name:     'personal-area.messages.details',
                    moduleId: PLATFORM.moduleName('modules/personal-area/messages/details/index'),
                    nav:      false,
                    title:    'Detalhes da mensagem',
                    settings: {
                        menu:        'personal-area',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.personal-area'},
                            {title: 'page.breadcrumbs.messages'},
                            {title: 'page.breadcrumbs.details'},
                        ],
                    },
                },
                // ==================== notifications ====================
                {
                    route:    'personal-area/notifications',
                    name:     'personal-area.notifications.index',
                    moduleId: PLATFORM.moduleName('modules/personal-area/notifications/index'),
                    nav:      true,
                    title:    'Notificações',
                    settings: {
                        menu:        'personal-area',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.personal-area'},
                            {title: 'page.breadcrumbs.notifications'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                {
                    route:    'personal-area/notifications/:id/details',
                    name:     'personal-area.notifications.details',
                    moduleId: PLATFORM.moduleName('modules/personal-area/notifications/details/index'),
                    nav:      false,
                    title:    'Detalhes da notificação',
                    settings: {
                        menu:        'personal-area',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.personal-area'},
                            {title: 'page.breadcrumbs.notifications'},
                            {title: 'page.breadcrumbs.details'},
                        ],
                    },
                },
                // ==================== users ====================
                {
                    route:    'administration/users',
                    name:     'administration.users.index',
                    moduleId: PLATFORM.moduleName('modules/administration/users/index'),
                    nav:      true,
                    title:    'Utilizadores',
                    settings: {
                        menu:        'administration',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.administration'},
                            {title: 'page.breadcrumbs.users'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                {
                    route:    'administration/users/create',
                    name:     'administration.users.create',
                    moduleId: PLATFORM.moduleName('modules/administration/users/create/index'),
                    nav:      false,
                    title:    'Criar utilizador',
                    settings: {
                        menu:        'administration',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.administration'},
                            {title: 'page.breadcrumbs.users'},
                            {title: 'page.breadcrumbs.new-record'},
                        ],
                    },
                },
                {
                    route:    'administration/users/:id/edit',
                    name:     'administration.users.edit',
                    moduleId: PLATFORM.moduleName('modules/administration/users/edit/index'),
                    nav:      false,
                    title:    'Editar utilizador',
                    settings: {
                        menu:        'administration',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.administration'},
                            {title: 'page.breadcrumbs.users'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                {
                    route:    'administration/users/:id/view',
                    name:     'administration.users.view',
                    moduleId: PLATFORM.moduleName('modules/administration/users/view/index'),
                    nav:      false,
                    title:    'Visualizar utilizador',
                    settings: {
                        menu:        'administration',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.administration'},
                            {title: 'page.breadcrumbs.users'},
                            {title: 'page.breadcrumbs.view-record'},
                        ],
                    },
                },
                // ==================== roles ====================
                {
                    route:    'administration/roles',
                    name:     'administration.roles.index',
                    moduleId: PLATFORM.moduleName('modules/administration/roles/index'),
                    nav:      true,
                    title:    'Perfis',
                    settings: {
                        menu:        'administration',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.administration'},
                            {title: 'page.breadcrumbs.roles'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                {
                    route:    'administration/roles/create',
                    name:     'administration.roles.create',
                    moduleId: PLATFORM.moduleName('modules/administration/roles/create/index'),
                    nav:      false,
                    title:    'Criar perfil',
                    settings: {
                        menu:        'administration',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.administration'},
                            {title: 'page.breadcrumbs.roles'},
                            {title: 'page.breadcrumbs.new-record'},
                        ],
                    },
                },
                {
                    route:    'administration/roles/:id/edit',
                    name:     'administration.roles.edit',
                    moduleId: PLATFORM.moduleName('modules/administration/roles/edit/index'),
                    nav:      false,
                    title:    'Editar perfil',
                    settings: {
                        menu:        'administration',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.administration'},
                            {title: 'page.breadcrumbs.roles'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                {
                    route:    'administration/roles/:id/view',
                    name:     'administration.roles.view',
                    moduleId: PLATFORM.moduleName('modules/administration/roles/view/index'),
                    nav:      false,
                    title:    'Visualizar perfil',
                    settings: {
                        menu:        'administration',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.administration'},
                            {title: 'page.breadcrumbs.roles'},
                            {title: 'page.breadcrumbs.view-record'},
                        ],
                    },
                },
                // ==================== user-groups ====================
                {
                    route:    'administration/user-groups',
                    name:     'administration.user-groups.index',
                    moduleId: PLATFORM.moduleName('modules/administration/user-groups/index'),
                    nav:      true,
                    title:    'List user groups',
                    settings: {
                        menu:        'administration',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.administration'},
                            {title: 'page.breadcrumbs.user-groups'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                {
                    route:    'administration/user-groups/create',
                    name:     'administration.user-groups.create',
                    moduleId: PLATFORM.moduleName('modules/administration/user-groups/create/index'),
                    nav:      false,
                    title:    'Create user group',
                    settings: {
                        menu:        'administration',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.administration'},
                            {title: 'page.breadcrumbs.user-groups'},
                            {title: 'page.breadcrumbs.new-record'},
                        ],
                    },
                },
                {
                    route:    'administration/user-groups/:id/edit',
                    name:     'administration.user-groups.edit',
                    moduleId: PLATFORM.moduleName('modules/administration/user-groups/edit/index'),
                    nav:      false,
                    title:    'Edit user group',
                    settings: {
                        menu:        'administration',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.administration'},
                            {title: 'page.breadcrumbs.user-groups'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                {
                    route:    'administration/user-groups/:id/view',
                    name:     'administration.user-groups.view',
                    moduleId: PLATFORM.moduleName('modules/administration/user-groups/view/index'),
                    nav:      false,
                    title:    'View user group',
                    settings: {
                        menu:        'administration',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.administration'},
                            {title: 'page.breadcrumbs.user-groups'},
                            {title: 'page.breadcrumbs.view-record'},
                        ],
                    },
                },
                // ==================== countries ====================
                {
                    route:    'administration/countries',
                    name:     'administration.countries.index',
                    moduleId: PLATFORM.moduleName('modules/administration/countries/index'),
                    nav:      true,
                    title:    'List countries',
                    settings: {
                        menu:        'administration',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.administration'},
                            {title: 'page.breadcrumbs.countries'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                // ==================== company-organizations ====================
                {
                    route:    'administration/company-organizations',
                    name:     'administration.company-organizations.index',
                    moduleId: PLATFORM.moduleName('modules/administration/company-organizations/index'),
                    nav:      true,
                    title:    'List company organizations',
                    settings: {
                        menu:        'administration',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.administration'},
                            {title: 'page.breadcrumbs.administration'},
                            {title: 'page.breadcrumbs.company-organizations'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                {
                    route:    'administration/company-organizations/create',
                    name:     'administration.company-organizations.create',
                    moduleId: PLATFORM.moduleName('modules/administration/company-organizations/create/index'),
                    nav:      false,
                    title:    'Create company organizations',
                    settings: {
                        menu:        'administration',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.administration'},
                            {title: 'page.breadcrumbs.company-organizations'},
                            {title: 'page.breadcrumbs.new-record'},
                        ],
                    },
                },
                {
                    route:    'administration/company-organizations/:id/edit',
                    name:     'administration.company-organizations.edit',
                    moduleId: PLATFORM.moduleName('modules/administration/company-organizations/edit/index'),
                    nav:      false,
                    title:    'Edit company organization',
                    settings: {
                        menu:        'administration',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.administration'},
                            {title: 'page.breadcrumbs.company-organizations'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                {
                    route:    'administration/company-organizations/:id/view',
                    name:     'administration.company-organizations.view',
                    moduleId: PLATFORM.moduleName('modules/administration/company-organizations/view/index'),
                    nav:      false,
                    title:    'View company organizations',
                    settings: {
                        menu:        'administration',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.administration'},
                            {title: 'page.breadcrumbs.company-organizations'},
                            {title: 'page.breadcrumbs.view-record'},
                        ],
                    },
                },
                // ==================== sources ====================
                {
                    route:    'legislation/management/sources',
                    name:     'legislation.management.sources.index',
                    moduleId: PLATFORM.moduleName('modules/legislation/management/sources/index'),
                    nav:      true,
                    title:    'List sources',
                    settings: {
                        menu:        'legislation.management',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.legislation'},
                            {title: 'page.breadcrumbs.management'},
                            {title: 'page.breadcrumbs.sources'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                {
                    route:    'legislation/management/sources/create',
                    name:     'legislation.management.sources.create',
                    moduleId: PLATFORM.moduleName('modules/legislation/management/sources/create/index'),
                    nav:      false,
                    title:    'Create source',
                    settings: {
                        menu:        'legislation.management',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.legislation'},
                            {title: 'page.breadcrumbs.management'},
                            {title: 'page.breadcrumbs.sources'},
                            {title: 'page.breadcrumbs.new-record'},
                        ],
                    },
                },
                {
                    route:    'legislation/management/sources/:id/edit',
                    name:     'legislation.management.sources.edit',
                    moduleId: PLATFORM.moduleName('modules/legislation/management/sources/edit/index'),
                    nav:      false,
                    title:    'Edit source',
                    settings: {
                        menu:        'legislation.management',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.legislation'},
                            {title: 'page.breadcrumbs.management'},
                            {title: 'page.breadcrumbs.sources'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                {
                    route:    'legislation/management/sources/:id/view',
                    name:     'legislation.management.sources.view',
                    moduleId: PLATFORM.moduleName('modules/legislation/management/sources/view/index'),
                    nav:      false,
                    title:    'View source',
                    settings: {
                        menu:        'legislation.management',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.legislation'},
                            {title: 'page.breadcrumbs.management'},
                            {title: 'page.breadcrumbs.sources'},
                            {title: 'page.breadcrumbs.view-record'},
                        ],
                    },
                },
                // ==================== diploma-types ====================
                {
                    route:    'legislation/management/diploma-types',
                    name:     'legislation.management.diploma-types.index',
                    moduleId: PLATFORM.moduleName('modules/legislation/management/diploma-types/index'),
                    nav:      true,
                    title:    'List legal connection types',
                    settings: {
                        menu:        'legislation.management',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.legislation'},
                            {title: 'page.breadcrumbs.management'},
                            {title: 'page.breadcrumbs.diploma-types'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                {
                    route:    'legislation/management/diploma-types/create',
                    name:     'legislation.management.diploma-types.create',
                    moduleId: PLATFORM.moduleName('modules/legislation/management/diploma-types/create/index'),
                    nav:      false,
                    title:    'Create legal connection type',
                    settings: {
                        menu:        'legislation.management',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.legislation'},
                            {title: 'page.breadcrumbs.management'},
                            {title: 'page.breadcrumbs.diploma-types'},
                            {title: 'page.breadcrumbs.new-record'},
                        ],
                    },
                },
                {
                    route:    'legislation/management/diploma-types/:id/edit',
                    name:     'legislation.management.diploma-types.edit',
                    moduleId: PLATFORM.moduleName('modules/legislation/management/diploma-types/edit/index'),
                    nav:      false,
                    title:    'Edit legal connection type',
                    settings: {
                        menu:        'legislation.management',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.legislation'},
                            {title: 'page.breadcrumbs.management'},
                            {title: 'page.breadcrumbs.diploma-types'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                {
                    route:    'legislation/management/diploma-types/:id/view',
                    name:     'legislation.management.diploma-types.view',
                    moduleId: PLATFORM.moduleName('modules/legislation/management/diploma-types/view/index'),
                    nav:      false,
                    title:    'View legal connection type',
                    settings: {
                        menu:        'legislation.management',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.legislation'},
                            {title: 'page.breadcrumbs.management'},
                            {title: 'page.breadcrumbs.diploma-types'},
                            {title: 'page.breadcrumbs.view-record'},
                        ],
                    },
                },
                // ==================== diploma-classifications ====================
                {
                    route:    'legislation/management/diploma-classifications',
                    name:     'legislation.management.diploma-classifications.index',
                    moduleId: PLATFORM.moduleName('modules/legislation/management/diploma-classifications/index'),
                    nav:      true,
                    title:    'List diploma classifications',
                    settings: {
                        menu:        'legislation.management',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.legislation'},
                            {title: 'page.breadcrumbs.management'},
                            {title: 'page.breadcrumbs.diploma-classifications'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                {
                    route:    'legislation/management/diploma-classifications/create',
                    name:     'legislation.management.diploma-classifications.create',
                    moduleId: PLATFORM.moduleName('modules/legislation/management/diploma-classifications/create/index'),
                    nav:      false,
                    title:    'Create diploma classification',
                    settings: {
                        menu:        'legislation.management',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.legislation'},
                            {title: 'page.breadcrumbs.management'},
                            {title: 'page.breadcrumbs.diploma-classifications'},
                            {title: 'page.breadcrumbs.new-record'},
                        ],
                    },
                },
                {
                    route:    'legislation/management/diploma-classifications/:id/edit',
                    name:     'legislation.management.diploma-classifications.edit',
                    moduleId: PLATFORM.moduleName('modules/legislation/management/diploma-classifications/edit/index'),
                    nav:      false,
                    title:    'Edit diploma classification',
                    settings: {
                        menu:        'legislation.management',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.legislation'},
                            {title: 'page.breadcrumbs.management'},
                            {title: 'page.breadcrumbs.diploma-classifications'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                {
                    route:    'legislation/management/diploma-classifications/:id',
                    name:     'legislation.management.diploma-classifications.view',
                    moduleId: PLATFORM.moduleName('modules/legislation/management/diploma-classifications/view/index'),
                    nav:      false,
                    title:    'View diploma classification',
                    settings: {
                        menu:        'legislation.management',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.legislation'},
                            {title: 'page.breadcrumbs.management'},
                            {title: 'page.breadcrumbs.diploma-classifications'},
                            {title: 'page.breadcrumbs.view-record'},
                        ],
                    },
                },
                // ==================== legal-connection-types ====================
                {
                    route:    'legislation/management/legal-connection-types',
                    name:     'legislation.management.legal-connection-types.index',
                    moduleId: PLATFORM.moduleName('modules/legislation/management/legal-connection-types/index'),
                    nav:      true,
                    title:    'List legal connection types',
                    settings: {
                        menu:        'legislation.management',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.legislation'},
                            {title: 'page.breadcrumbs.management'},
                            {title: 'page.breadcrumbs.legal-connection-types'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                {
                    route:    'legislation/management/legal-connection-types/create',
                    name:     'legislation.management.legal-connection-types.create',
                    moduleId: PLATFORM.moduleName('modules/legislation/management/legal-connection-types/create/index'),
                    nav:      false,
                    title:    'Create legal connection type',
                    settings: {
                        menu:        'legislation.management',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.legislation'},
                            {title: 'page.breadcrumbs.management'},
                            {title: 'page.breadcrumbs.legal-connection-types'},
                            {title: 'page.breadcrumbs.new-record'},
                        ],
                    },
                },
                {
                    route:    'legislation/management/legal-connection-types/:id/edit',
                    name:     'legislation.management.legal-connection-types.edit',
                    moduleId: PLATFORM.moduleName('modules/legislation/management/legal-connection-types/edit/index'),
                    nav:      false,
                    title:    'Edit legal connection type',
                    settings: {
                        menu:        'legislation.management',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.legislation'},
                            {title: 'page.breadcrumbs.management'},
                            {title: 'page.breadcrumbs.legal-connection-types'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                {
                    route:    'legislation/management/legal-connection-types/:id/view',
                    name:     'legislation.management.legal-connection-types.view',
                    moduleId: PLATFORM.moduleName('modules/legislation/management/legal-connection-types/view/index'),
                    nav:      false,
                    title:    'View legal connection type',
                    settings: {
                        menu:        'legislation.management',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.legislation'},
                            {title: 'page.breadcrumbs.management'},
                            {title: 'page.breadcrumbs.legal-connection-types'},
                            {title: 'page.breadcrumbs.view-record'},
                        ],
                    },
                },
                // ==================== applicability-types ====================
                {
                    route:    'legislation/management/applicability-types',
                    name:     'legislation.management.applicability-types.index',
                    moduleId: PLATFORM.moduleName('modules/legislation/management/applicability-types/index'),
                    nav:      true,
                    title:    'List applicability types',
                    settings: {
                        menu:        'legislation.management',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.legislation'},
                            {title: 'page.breadcrumbs.management'},
                            {title: 'page.breadcrumbs.applicability-types'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                {
                    route:    'legislation/management/applicability-types/create',
                    name:     'legislation.management.applicability-types.create',
                    moduleId: PLATFORM.moduleName('modules/legislation/management/applicability-types/create/index'),
                    nav:      false,
                    title:    'Create applicability type',
                    settings: {
                        menu:        'legislation.management',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.legislation'},
                            {title: 'page.breadcrumbs.management'},
                            {title: 'page.breadcrumbs.applicability-types'},
                            {title: 'page.breadcrumbs.new-record'},
                        ],
                    },
                },
                {
                    route:    'legislation/management/applicability-types/:id/edit',
                    name:     'legislation.management.applicability-types.edit',
                    moduleId: PLATFORM.moduleName('modules/legislation/management/applicability-types/edit/index'),
                    nav:      false,
                    title:    'Edit applicability type',
                    settings: {
                        menu:        'legislation.management',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.legislation'},
                            {title: 'page.breadcrumbs.management'},
                            {title: 'page.breadcrumbs.applicability-types'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                {
                    route:    'legislation/management/applicability-types/:id/view',
                    name:     'legislation.management.applicability-types.view',
                    moduleId: PLATFORM.moduleName('modules/legislation/management/applicability-types/view/index'),
                    nav:      false,
                    title:    'View applicability type',
                    settings: {
                        menu:        'legislation.management',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.legislation'},
                            {title: 'page.breadcrumbs.management'},
                            {title: 'page.breadcrumbs.applicability-types'},
                            {title: 'page.breadcrumbs.view-record'},
                        ],
                    },
                },
                // ==================== conformity-evaluation-statuses ====================
                {
                    route:    'legislation/management/conformity-evaluation-statuses',
                    name:     'legislation.management.conformity-evaluation-statuses.index',
                    moduleId: PLATFORM.moduleName('modules/legislation/management/conformity-evaluation-statuses/index'),
                    nav:      true,
                    title:    'List conformity evaluation statuses',
                    settings: {
                        menu:        'legislation.management',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.legislation'},
                            {title: 'page.breadcrumbs.management'},
                            {title: 'page.breadcrumbs.conformity-evaluation-statuses'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                {
                    route:    'legislation/management/conformity-evaluation-statuses/create',
                    name:     'legislation.management.conformity-evaluation-statuses.create',
                    moduleId: PLATFORM.moduleName('modules/legislation/management/conformity-evaluation-statuses/create/index'),
                    nav:      false,
                    title:    'Create conformity evaluation statuses',
                    settings: {
                        menu:        'legislation.management',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.legislation'},
                            {title: 'page.breadcrumbs.management'},
                            {title: 'page.breadcrumbs.conformity-evaluation-statuses'},
                            {title: 'page.breadcrumbs.new-record'},
                        ],
                    },
                },
                {
                    route:    'legislation/management/conformity-evaluation-statuses/:id/edit',
                    name:     'legislation.management.conformity-evaluation-statuses.edit',
                    moduleId: PLATFORM.moduleName('modules/legislation/management/conformity-evaluation-statuses/edit/index'),
                    nav:      false,
                    title:    'Edit conformity evaluation status',
                    settings: {
                        menu:        'legislation.management',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.legislation'},
                            {title: 'page.breadcrumbs.management'},
                            {title: 'page.breadcrumbs.conformity-evaluation-statuses'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                {
                    route:    'legislation/management/conformity-evaluation-statuses/:id/view',
                    name:     'legislation.management.conformity-evaluation-statuses.view',
                    moduleId: PLATFORM.moduleName('modules/legislation/management/conformity-evaluation-statuses/view/index'),
                    nav:      false,
                    title:    'View conformity evaluation status',
                    settings: {
                        menu:        'legislation.management',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.legislation'},
                            {title: 'page.breadcrumbs.management'},
                            {title: 'page.breadcrumbs.conformity-evaluation-statuses'},
                            {title: 'page.breadcrumbs.view-record'},
                        ],
                    },
                },
                // ==================== diploma-settings ====================
                {
                    route:    'legislation/management/diploma-settings',
                    name:     'legislation.management.diploma-settings.edit',
                    moduleId: PLATFORM.moduleName('modules/legislation/management/diploma-settings/edit/index'),
                    nav:      false,
                    title:    'Edit diploma settings',
                    settings: {
                        menu:        'legislation.management',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.legislation'},
                            {title: 'page.breadcrumbs.management'},
                            {title: 'page.breadcrumbs.general-settings'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                // ==================== jurist-diplomas ====================
                {
                    route:              'legislation/jurist/diplomas',
                    name:               'legislation.jurist.diplomas.index',
                    moduleId:           PLATFORM.moduleName('modules/legislation/jurist/diplomas/index'),
                    activationStrategy: activationStrategy.replace,
                    nav:                true,
                    title:              'List diplomas',
                    settings:           {
                        menu:        'legislation.jurist',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.legislation'},
                            {title: 'page.breadcrumbs.jurist'},
                            {title: 'page.breadcrumbs.diplomas'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                {
                    route:    'legislation/jurist/diplomas/create',
                    name:     'legislation.jurist.diplomas.create',
                    moduleId: PLATFORM.moduleName('modules/legislation/jurist/diplomas/create/index'),
                    nav:      false,
                    title:    'Create diploma',
                    settings: {
                        menu:        'legislation.jurist',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.legislation'},
                            {title: 'page.breadcrumbs.jurist'},
                            {title: 'page.breadcrumbs.diplomas'},
                            {title: 'page.breadcrumbs.new-record'},
                        ],
                    },
                },
                {
                    route:    'legislation/jurist/diplomas/:id/edit',
                    name:     'legislation.jurist.diplomas.edit',
                    moduleId: PLATFORM.moduleName('modules/legislation/jurist/diplomas/edit/index'),
                    nav:      false,
                    title:    'Edit diploma',
                    settings: {
                        menu:        'legislation.jurist',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.legislation'},
                            {title: 'page.breadcrumbs.jurist'},
                            {title: 'page.breadcrumbs.diplomas'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                {
                    route:    'legislation/jurist/diplomas/:id/view',
                    name:     'legislation.jurist.diplomas.view',
                    moduleId: PLATFORM.moduleName('modules/legislation/jurist/diplomas/view/index'),
                    nav:      false,
                    title:    'View diploma',
                    settings: {
                        menu:        'legislation.jurist',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.legislation'},
                            {title: 'page.breadcrumbs.jurist'},
                            {title: 'page.breadcrumbs.diplomas'},
                            {title: 'page.breadcrumbs.view-record'},
                        ],
                    },
                },
                {
                    route:    'legislation/jurist/global-messages',
                    name:     'legislation.jurist.global-messages.index',
                    moduleId: PLATFORM.moduleName('modules/legislation/jurist/global-messages/index'),
                    nav:      false,
                    title:    'Create global message',
                    settings: {
                        menu:        'legislation.jurist',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.legislation'},
                            {title: 'page.breadcrumbs.jurist'},
                            {title: 'page.breadcrumbs.global-messages'},
                            {title: 'page.breadcrumbs.new-record'},
                        ],
                    },
                },
                // ==================== consultant-diplomas ====================
                {
                    route:              'legislation/consultant/diplomas',
                    name:               'legislation.consultant.diplomas.index',
                    moduleId:           PLATFORM.moduleName('modules/legislation/consultant/diplomas/index'),
                    activationStrategy: activationStrategy.replace,
                    nav:                true,
                    title:              'List diplomas',
                    settings:           {
                        menu:        'legislation.consultant',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.legislation'},
                            {title: 'page.breadcrumbs.consultant'},
                            {title: 'page.breadcrumbs.diplomas'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                {
                    route:    'legislation/consultant/diplomas/:id/view',
                    name:     'legislation.consultant.diplomas.view',
                    moduleId: PLATFORM.moduleName('modules/legislation/consultant/diplomas/view/index'),
                    nav:      false,
                    title:    'View diploma',
                    settings: {
                        menu:        'legislation.consultant',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.legislation'},
                            {title: 'page.breadcrumbs.consultant'},
                            {title: 'page.breadcrumbs.diplomas'},
                            {title: 'page.breadcrumbs.view-record'},
                        ],
                    },
                },
                // ==================== consultant-applicability-analysis ====================
                {
                    route:    'legislation/consultant/diplomas/applicability/analysis',
                    name:     'legislation.consultant.applicability.analysis.index',
                    moduleId: PLATFORM.moduleName('modules/legislation/consultant/applicability/analysis/index'),
                    nav:      true,
                    title:    'List applicability analysis',
                    settings: {
                        menu:        'legislation.consultant',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.legislation'},
                            {title: 'page.breadcrumbs.consultant'},
                            {title: 'page.breadcrumbs.applicability'},
                            {title: 'page.breadcrumbs.analysis'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                {
                    route:    'legislation/consultant/diplomas/:id/applicability/analysis/perform',
                    name:     'legislation.consultant.applicability.analysis.perform',
                    moduleId: PLATFORM.moduleName('modules/legislation/consultant/applicability/analysis/perform/index'),
                    nav:      false,
                    title:    'Perform applicability analysis',
                    settings: {
                        menu:        'legislation.consultant',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.legislation'},
                            {title: 'page.breadcrumbs.consultant'},
                            {title: 'page.breadcrumbs.applicability'},
                            {title: 'page.breadcrumbs.analysis'},
                            {title: 'page.breadcrumbs.perform'},
                        ],
                    },
                },
                // ==================== consultant-applicability-revision ====================
                {
                    route:    'legislation/consultant/applicability/revision',
                    name:     'legislation.consultant.applicability.revision.index',
                    moduleId: PLATFORM.moduleName('modules/legislation/consultant/applicability/revision/index'),
                    nav:      true,
                    title:    'List applicability revision',
                    settings: {
                        menu:        'legislation.consultant',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.legislation'},
                            {title: 'page.breadcrumbs.consultant'},
                            {title: 'page.breadcrumbs.applicability'},
                            {title: 'page.breadcrumbs.revision'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                {
                    route:    'legislation/consultant/diplomas/applicability/:id/revision/perform',
                    name:     'legislation.consultant.applicability.revision.perform',
                    moduleId: PLATFORM.moduleName('modules/legislation/consultant/applicability/revision/perform/index'),
                    nav:      false,
                    title:    'Perform applicability revision',
                    settings: {
                        menu:        'legislation.consultant',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.legislation'},
                            {title: 'page.breadcrumbs.consultant'},
                            {title: 'page.breadcrumbs.applicability'},
                            {title: 'page.breadcrumbs.revision'},
                            {title: 'page.breadcrumbs.perform'},
                        ],
                    },
                },
                // ==================== client-diplomas ====================
                {
                    route:    'legislation/client/diplomas',
                    name:     'legislation.client.diplomas.index',
                    moduleId: PLATFORM.moduleName('modules/legislation/client/diplomas/index'),
                    nav:      true,
                    title:    'List diplomas',
                    settings: {
                        menu:        'legislation.client',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.legislation'},
                            {title: 'page.breadcrumbs.client'},
                            {title: 'page.breadcrumbs.diplomas'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                {
                    route:    'legislation/client/diplomas/create',
                    name:     'legislation.client.diplomas.create',
                    moduleId: PLATFORM.moduleName('modules/legislation/client/diplomas/create/index'),
                    nav:      false,
                    title:    'Create diploma',
                    settings: {
                        menu:        'legislation.client',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.legislation'},
                            {title: 'page.breadcrumbs.client'},
                            {title: 'page.breadcrumbs.diplomas'},
                            {title: 'page.breadcrumbs.new-record'},
                        ],
                    },
                },
                {
                    route:    'legislation/client/diplomas/:id/edit',
                    name:     'legislation.client.diplomas.edit',
                    moduleId: PLATFORM.moduleName('modules/legislation/client/diplomas/edit/index'),
                    nav:      false,
                    title:    'Edit diploma',
                    settings: {
                        menu:        'legislation.client',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.legislation'},
                            {title: 'page.breadcrumbs.client'},
                            {title: 'page.breadcrumbs.diplomas'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                {
                    route:    'legislation/client/diplomas/:id/view',
                    name:     'legislation.client.diplomas.view',
                    moduleId: PLATFORM.moduleName('modules/legislation/client/diplomas/view/index'),
                    nav:      false,
                    title:    'View diploma',
                    settings: {
                        menu:        'legislation.client',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.legislation'},
                            {title: 'page.breadcrumbs.client'},
                            {title: 'page.breadcrumbs.diplomas'},
                            {title: 'page.breadcrumbs.view-record'},
                        ],
                    },
                },
                // ==================== client-conformity-evaluations-preparation-stage ====================
                {
                    route:    'legislation/client/conformity-evaluations/preparation',
                    name:     'legislation.client.conformity-evaluations.preparation.index',
                    moduleId: PLATFORM.moduleName('modules/legislation/client/conformity-evaluations/preparation/index'),
                    nav:      true,
                    title:    'List conformity evaluations',
                    settings: {
                        menu:        'legislation.client',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.legislation'},
                            {title: 'page.breadcrumbs.client'},
                            {title: 'page.breadcrumbs.conformity'},
                            {title: 'page.breadcrumbs.preparation'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                {
                    route:    'legislation/client/conformity-evaluations/preparation/create',
                    name:     'legislation.client.conformity-evaluations.preparation.create',
                    moduleId: PLATFORM.moduleName('modules/legislation/client/conformity-evaluations/preparation/create/index'),
                    nav:      false,
                    title:    'Create conformity evaluation',
                    settings: {
                        menu:        'legislation.client',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.legislation'},
                            {title: 'page.breadcrumbs.client'},
                            {title: 'page.breadcrumbs.conformity'},
                            {title: 'page.breadcrumbs.preparation'},
                            {title: 'page.breadcrumbs.new-record'},
                        ],
                    },
                },
                {
                    route:    'legislation/client/conformity-evaluations/:id/preparation/edit',
                    name:     'legislation.client.conformity-evaluations.preparation.edit',
                    moduleId: PLATFORM.moduleName('modules/legislation/client/conformity-evaluations/preparation/edit/index'),
                    nav:      false,
                    title:    'Edit conformity evaluation',
                    settings: {
                        menu:        'legislation.client',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.legislation'},
                            {title: 'page.breadcrumbs.client'},
                            {title: 'page.breadcrumbs.conformity'},
                            {title: 'page.breadcrumbs.preparation'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                {
                    route:    'legislation/client/conformity-evaluations/:id/preparation/view',
                    name:     'legislation.client.conformity-evaluations.preparation.view',
                    moduleId: PLATFORM.moduleName('modules/legislation/client/conformity-evaluations/preparation/view/index'),
                    nav:      false,
                    title:    'View conformity evaluation',
                    settings: {
                        menu:        'legislation.client',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.legislation'},
                            {title: 'page.breadcrumbs.client'},
                            {title: 'page.breadcrumbs.conformity'},
                            {title: 'page.breadcrumbs.preparation'},
                            {title: 'page.breadcrumbs.view-record'},
                        ],
                    },
                },
                // ==================== client-conformity-evaluations-execution-stage ====================
                {
                    route:    'legislation/client/conformity-evaluations/execution',
                    name:     'legislation.client.conformity-evaluations.execution.index',
                    moduleId: PLATFORM.moduleName('modules/legislation/client/conformity-evaluations/execution/index'),
                    nav:      true,
                    title:    'List conformity evaluations',
                    settings: {
                        menu:        'legislation.client',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.legislation'},
                            {title: 'page.breadcrumbs.client'},
                            {title: 'page.breadcrumbs.conformity'},
                            {title: 'page.breadcrumbs.execution'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                {
                    route:    'legislation/client/conformity-evaluations/:id/execution/edit',
                    name:     'legislation.client.conformity-evaluations.execution.edit',
                    moduleId: PLATFORM.moduleName('modules/legislation/client/conformity-evaluations/execution/edit/index'),
                    nav:      false,
                    title:    'Edit conformity evaluation',
                    settings: {
                        menu:        'legislation.client',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.legislation'},
                            {title: 'page.breadcrumbs.client'},
                            {title: 'page.breadcrumbs.conformity'},
                            {title: 'page.breadcrumbs.execution'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                {
                    route:    'legislation/client/conformity-evaluations/:id/execution/view',
                    name:     'legislation.client.conformity-evaluations.execution.view',
                    moduleId: PLATFORM.moduleName('modules/legislation/client/conformity-evaluations/execution/view/index'),
                    nav:      false,
                    title:    'View conformity evaluation',
                    settings: {
                        menu:        'legislation.client',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.legislation'},
                            {title: 'page.breadcrumbs.client'},
                            {title: 'page.breadcrumbs.conformity'},
                            {title: 'page.breadcrumbs.execution'},
                            {title: 'page.breadcrumbs.view-record'},
                        ],
                    },
                },
                // ==================== client-conformity-evaluations-executed-stage ====================
                {
                    route:    'legislation/client/conformity-evaluations/executed',
                    name:     'legislation.client.conformity-evaluations.executed.index',
                    moduleId: PLATFORM.moduleName('modules/legislation/client/conformity-evaluations/executed/index'),
                    nav:      true,
                    title:    'List conformity evaluations',
                    settings: {
                        menu:        'legislation.client',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.legislation'},
                            {title: 'page.breadcrumbs.client'},
                            {title: 'page.breadcrumbs.conformity'},
                            {title: 'page.breadcrumbs.executed'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                {
                    route:    'legislation/client/conformity-evaluations/:id/executed/view',
                    name:     'legislation.client.conformity-evaluations.executed.view',
                    moduleId: PLATFORM.moduleName('modules/legislation/client/conformity-evaluations/executed/view/index'),
                    nav:      false,
                    title:    'View conformity evaluation',
                    settings: {
                        menu:        'legislation.client',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.legislation'},
                            {title: 'page.breadcrumbs.client'},
                            {title: 'page.breadcrumbs.conformity'},
                            {title: 'page.breadcrumbs.executed'},
                            {title: 'page.breadcrumbs.view-record'},
                        ],
                    },
                },
                // ==================== client-applicability-analysis ====================
                {
                    route:    'legislation/client/diplomas/:id/applicability/analysis/perform',
                    name:     'legislation.client.applicability.analysis.perform',
                    moduleId: PLATFORM.moduleName('modules/legislation/client/applicability/analysis/perform/index'),
                    nav:      false,
                    title:    'Perform applicability analysis',
                    settings: {
                        menu:        'legislation.client',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.legislation'},
                            {title: 'page.breadcrumbs.client'},
                            {title: 'page.breadcrumbs.applicability'},
                            {title: 'page.breadcrumbs.analysis'},
                            {title: 'page.breadcrumbs.perform'},
                        ],
                    },
                },
                // ==================== client-actions-without-alerts ====================
                {
                    route:    'legislation/client/actions-without-alerts',
                    name:     'legislation.client.actions-without-alerts.index',
                    moduleId: PLATFORM.moduleName('modules/legislation/client/actions-without-alerts/index'),
                    nav:      false,
                    title:    'List actions without alerts',
                    settings: {
                        menu:        'legislation.client',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.legislation'},
                            {title: 'page.breadcrumbs.client'},
                            {title: 'page.breadcrumbs.actions-without-alerts'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                // ==================== personal-area-diplomas ====================
                {
                    route:              'legislation/client/personal-area/diplomas',
                    name:               'legislation.client.personal-area.diplomas.index',
                    moduleId:           PLATFORM.moduleName('modules/legislation/client/personal-area/diplomas/index'),
                    activationStrategy: activationStrategy.replace,
                    nav:                true,
                    title:              'List diplomas',
                    settings:           {
                        menu:        'legislation.client',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.legislation'},
                            {title: 'page.breadcrumbs.client'},
                            {title: 'page.breadcrumbs.personal-area'},
                            {title: 'page.breadcrumbs.diplomas'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                // ==================== personal-area-my-plan-actions ====================
                {
                    route:    'legislation/client/personal-area/my-actions',
                    name:     'legislation.client.personal-area.my-actions.index',
                    moduleId: PLATFORM.moduleName('modules/legislation/client/personal-area/my-actions/index'),
                    nav:      true,
                    title:    'List my plan actions',
                    settings: {
                        menu:        'legislation.client',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.legislation'},
                            {title: 'page.breadcrumbs.client'},
                            {title: 'page.breadcrumbs.personal-area'},
                            {title: 'page.breadcrumbs.my-actions'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                {
                    route:    'legislation/client/personal-area/my-actions/:id/edit',
                    name:     'legislation.client.personal-area.my-actions.edit',
                    moduleId: PLATFORM.moduleName('modules/legislation/client/personal-area/my-actions/edit/index'),
                    nav:      false,
                    title:    'Edit my plan action',
                    settings: {
                        menu:        'legislation.client',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.legislation'},
                            {title: 'page.breadcrumbs.client'},
                            {title: 'page.breadcrumbs.personal-area'},
                            {title: 'page.breadcrumbs.my-actions'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                {
                    route:    'legislation/client/personal-area/my-actions/:id/view',
                    name:     'legislation.client.personal-area.my-actions.view',
                    moduleId: PLATFORM.moduleName('modules/legislation/client/personal-area/my-actions/view/index'),
                    nav:      false,
                    title:    'View my plan action',
                    settings: {
                        menu:        'legislation.client',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.legislation'},
                            {title: 'page.breadcrumbs.client'},
                            {title: 'page.breadcrumbs.personal-area'},
                            {title: 'page.breadcrumbs.my-actions'},
                            {title: 'page.breadcrumbs.view-record'},
                        ],
                    },
                },
                // ==================== reports ====================
                {
                    route:    'legislation/client/reports',
                    name:     'legislation.client.reports.index',
                    moduleId: PLATFORM.moduleName('modules/legislation/client/reports/index'),
                    nav:      true,
                    title:    'Reports',
                    settings: {
                        menu:        'legislation',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.legislation'},
                            {title: 'page.breadcrumbs.client'},
                            {title: 'page.breadcrumbs.reports'},
                        ],
                    },
                },
            ]);
        };

        this.router.configure(appRouterConfig);
    }

}
