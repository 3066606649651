import { GlobalConfig } from 'resources/configs/global-config';

let AureliaAuthConfig = {
    baseUrl:        GlobalConfig.backendBaseApiUrl,
    loginUrl:       'auth/login',
    loginRedirect:  '/#/dashboard',
    logoutUrl:      null,
    logoutRedirect: '/#/',

    accessTokenProp: 'token',
    storageKey:      'aurelia_authentication',

    withCredentials: false,
};

export { AureliaAuthConfig };
